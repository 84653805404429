/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.thumbnail,
.img-thumbnail {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.img-space {
  margin: 0.5em 0;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
  background-color: #e8e8e8;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-image: -webkit-linear-gradient(top, #8D3C3B 0%, #7b3433 100%);
  background-image: -o-linear-gradient(top, #8D3C3B 0%, #7b3433 100%);
  background-image: linear-gradient(to bottom, #8D3C3B 0%, #7b3433 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8d3c3b', endColorstr='#ff7b3433', GradientType=0);
  background-color: #7b3433;
}
.dropdown-menu > li > a {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.navbar-default {
  background-color: #9A4140;
  border: none;
  border-radius: 0;
}
.navbar-default .navbar-nav > li > a {
  color: #fff;
  text-shadow: none;
}
.navbar-brand,
.navbar-nav > li > a {
  color: #fff;
}
.navbar-inverse {
  background-image: -webkit-linear-gradient(top, #3c3c3c 0%, #222 100%);
  background-image: -o-linear-gradient(top, #3c3c3c 0%, #222 100%);
  background-image: linear-gradient(to bottom, #3c3c3c 0%, #222 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border-radius: 4px;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .active > a {
  background-image: -webkit-linear-gradient(top, #080808 0%, #0f0f0f 100%);
  background-image: -o-linear-gradient(top, #080808 0%, #0f0f0f 100%);
  background-image: linear-gradient(to bottom, #080808 0%, #0f0f0f 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff080808', endColorstr='#ff0f0f0f', GradientType=0);
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
}
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}
@media (max-width: 767px) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-image: -webkit-linear-gradient(top, #8D3C3B 0%, #7b3433 100%);
    background-image: -o-linear-gradient(top, #8D3C3B 0%, #7b3433 100%);
    background-image: linear-gradient(to bottom, #8D3C3B 0%, #7b3433 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8d3c3b', endColorstr='#ff7b3433', GradientType=0);
  }
}
/*.alert {
  text-shadow: 0 1px 0 rgba(255,255,255,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.05);
  .box-shadow(@shadow);
}

// Mixin for generating new styles
.alert-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 7.5%));
  border-color: darken(@color, 15%);
}

// Apply the mixin to the alerts
.alert-success    { .alert-styles(@alert-success-bg); }
.alert-info       { .alert-styles(@alert-info-bg); }
.alert-warning    { .alert-styles(@alert-warning-bg); }
.alert-danger     { .alert-styles(@alert-danger-bg); }
*/
.progress {
  background-image: -webkit-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
}
.progress-bar {
  background-image: -webkit-linear-gradient(top, #8D3C3B 0%, #692d2c 100%);
  background-image: -o-linear-gradient(top, #8D3C3B 0%, #692d2c 100%);
  background-image: linear-gradient(to bottom, #8D3C3B 0%, #692d2c 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8d3c3b', endColorstr='#ff692d2c', GradientType=0);
}
.progress-bar-success {
  background-image: -webkit-linear-gradient(top, #5cb85c 0%, #449d44 100%);
  background-image: -o-linear-gradient(top, #5cb85c 0%, #449d44 100%);
  background-image: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff449d44', GradientType=0);
}
.progress-bar-info {
  background-image: -webkit-linear-gradient(top, #5bc0de 0%, #31b0d5 100%);
  background-image: -o-linear-gradient(top, #5bc0de 0%, #31b0d5 100%);
  background-image: linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff31b0d5', GradientType=0);
}
.progress-bar-warning {
  background-image: -webkit-linear-gradient(top, #f0ad4e 0%, #ec971f 100%);
  background-image: -o-linear-gradient(top, #f0ad4e 0%, #ec971f 100%);
  background-image: linear-gradient(to bottom, #f0ad4e 0%, #ec971f 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffec971f', GradientType=0);
}
.progress-bar-danger {
  background-image: -webkit-linear-gradient(top, #d9534f 0%, #c9302c 100%);
  background-image: -o-linear-gradient(top, #d9534f 0%, #c9302c 100%);
  background-image: linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc9302c', GradientType=0);
}
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.list-group {
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 #692d2c;
  background-image: -webkit-linear-gradient(top, #8D3C3B 0%, #723130 100%);
  background-image: -o-linear-gradient(top, #8D3C3B 0%, #723130 100%);
  background-image: linear-gradient(to bottom, #8D3C3B 0%, #723130 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8d3c3b', endColorstr='#ff723130', GradientType=0);
  border-color: #723130;
}
.list-group-item.active .badge,
.list-group-item.active:hover .badge,
.list-group-item.active:focus .badge {
  text-shadow: none;
}
.panel {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.well {
  background-image: -webkit-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #e8e8e8 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8e8e8', endColorstr='#fff5f5f5', GradientType=0);
  border-color: #dcdcdc;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
}
p {
  margin: 0 0 1em;
}
a.logo:hover {
  text-decoration: none;
}
@media (max-width: 767px) {
  .logo {
    display: none;
  }
}
.logoRow1 {
  background: #F8F8E1;
  color: #8D3C3B;
  font-family: "Book Antiqua", Georgia, serif;
  text-align: center;
  font-size: 8vw;
  text-decoration: none;
}
@media (min-width: 480px) {
  .logoRow1 {
    font-size: 2.52em;
  }
}
@media (min-width: 768px) {
  .logoRow1 {
    font-size: 3.6em;
  }
}
@media (min-width: 992px) {
  .logoRow1 {
    font-size: 4.2em;
  }
}
@media (min-width: 1200px) {
  .logoRow1 {
    font-size: 4.8em;
  }
}
.logoRow2 {
  background: #8D3C3B;
  color: #F8F8E1;
  font-family: "Book Antiqua", Georgia, serif;
  text-align: center;
  font-size: 5vw;
  text-decoration: none;
}
@media (min-width: 480px) {
  .logoRow2 {
    font-size: 1.8em;
  }
}
@media (min-width: 768px) {
  .logoRow2 {
    font-size: 2.16em;
  }
}
@media (min-width: 992px) {
  .logoRow2 {
    font-size: 2.76em;
  }
}
@media (min-width: 1200px) {
  .logoRow2 {
    font-size: 3.24em;
  }
}
.logoRow1 a:hover,
.logoRow2 a:hover {
  text-decoration: none;
}
.logoRow2 a {
  color: #fff;
}
@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    background: #fff;
    font-size: 1em;
  }
}
@media (max-width: 992px) {
  .navbar-nav .open .dropdown-menu li {
    padding-top: 0.5em;
    padding-bottom: 1em;
  }
}
@media (max-width: 991px) {
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 0.5em 2.5em;
  }
}
@media (min-width: 480px) {
  #homeHero img {
    margin-bottom: 1em;
  }
}
#homeHero figcaption {
  background: rgba(248, 248, 225, 0.8);
  width: auto;
  padding: 0.5em 1em;
  position: absolute;
  bottom: 1em;
  right: 1em;
}
@media (max-width: 480px) {
  #homeHero figcaption {
    position: relative;
    bottom: 0;
    left: 0;
    margin-bottom: 1em;
  }
}
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: bold;
}
.secondaryBG {
  background-color: #F8F8E1;
}
.navbar {
  min-height: 2em;
}
.navbar-nav > li > a {
  padding-top: 1em;
  padding-bottom: 1em;
}
.navbar-nav > li {
  padding: 0 1em;
}
.navbar-brand {
  font-size: 1em;
  padding: 0;
  height: auto;
  line-height: 1.42857143;
  width: 100%;
}
@media (min-width: 767px) {
  .navbar-brand {
    display: none;
  }
}
.navbar-toggle {
  margin: -17vw 3vw;
  padding: 2.5vw;
}
@media (min-width: 480px) {
  .navbar-toggle {
    margin: -6em 1em;
    padding: 0.9em;
  }
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #8D3C3B;
}
.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 1vw;
}
@media (min-width: 480px) {
  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 0.4em;
  }
}
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #F8F8E1;
}
#home .navbar {
  margin-bottom: 0;
}
footer > div {
  background-color: #8D3C3B;
  padding-top: 2em;
  padding-bottom: 1em;
  color: #fff;
  text-align: center;
}
footer > div a {
  color: #fff;
}
.cookie-disclaimer {
  border-radius: 0;
  background-color: #231F20;
  margin-bottom: 0;
  border: none;
  color: #fff;
}
.cookie-disclaimer a {
  color: #B85856;
}
.socialFooter {
  margin-bottom: 10px;
}
.socialFooter img {
  height: 3em;
  margin-right: 1em;
}
.alert.alert-msg {
  color: #fff;
  background-color: #6aa6a5;
  border-color: #6a90aa;
}
.alert.alert-msg a {
  color: #f2e2d2;
}
.label {
  color: #333333;
}
#contactus {
  background: #eeeeee;
  padding: 1em;
}
form label {
  font-size: 1.32em;
}
.contactHeader {
  color: #fff;
  padding: 0.75em;
  background: #8D3C3B;
  font-size: 1.5em;
}
.frozen div.input {
  background: #f9f9f9;
  padding: 1em 0.5em;
}
p.contactEmail {
  background-image: url("/img/contact/contact-email.png");
  background-repeat: no-repeat;
  padding: 0.5em 0 0.5em 4em;
  margin-bottom: 2em;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  p.contactEmail {
    background-image: url("/img/contact/contact-email@2x.png");
    background-size: 40px 29px;
  }
}
p.contactPhone {
  background-image: url("/img/contact/contact-phone.png");
  background-repeat: no-repeat;
  padding: 1.2em 0 1.2em 4em;
  margin-bottom: 2em;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  p.contactPhone {
    background-image: url("/img/contact/contact-phone@2x.png");
    background-size: 40px 46px;
  }
}
p.contactAddress {
  background-image: url("/img/contact/contact-address.png");
  background-repeat: no-repeat;
  padding: 0 0 0 4em;
  margin-bottom: 2em;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  p.contactAddress {
    background-image: url("/img/contact/contact-address@2x.png");
    background-size: 40px 51px;
  }
}
.contactSocial {
  padding: 1em 0 1em 4em;
  margin: 0 0 1em;
  display: block;
  background-size: 40px;
  background-repeat: no-repeat;
}
.contactFB {
  background-image: url('/img/contact/contact-facebook.svg');
}
.contactTW {
  background-image: url('/img/contact/contact-twitter.svg');
}
.contactIN {
  background-image: url('/img/contact/contact-instagram.svg');
}
.has-error .form-control,
.has-error label {
  color: #d9534f;
}
.envStatus {
  text-align: center;
  padding: 10px;
  background-color: crimson;
  color: #fff;
}
.required {
  display: none;
}
#stockists .panel-body {
  max-height: 50em;
  overflow-y: scroll;
}
.stockistLogo {
  height: 45px;
  width: 100%;
  vertical-align: middle;
}
.stockistLogo img {
  max-width: 100%;
  max-height: 45px;
}
.panel-heading {
  background: #8D3C3B;
  color: #fff;
  font-size: 1.32em;
}
.catCaption {
  position: relative;
  margin-bottom: 2em;
}
.catCaption img {
  display: block;
  width: 100%;
  min-height: 199px;
  background-color: #f0f0f0;
}
.catCaption span {
  width: 100%;
  padding: 1em 0.5em;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(248, 248, 225, 0.8);
}
.table {
  margin-top: 1em;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: none;
}
#swatch .row {
  margin-bottom: 1em;
}
.colourSwatch {
  height: 4.5em;
}
.colourSwatch img {
  width: 100%;
  height: 100%;
}
.colName {
  font-weight: bold;
}
.colCode,
.colSeries {
  font-size: 1.02em;
}
.colOpacity {
  height: 15px;
  width: 15px;
  background: url('/img/opacity-sprite.png') no-repeat;
  margin-top: 1.5em;
}
.colOpacity.op-1 {
  background-position: 0 0;
}
.colOpacity.op-2 {
  background-position: -15px 0;
}
.colOpacity.op-3 {
  background-position: -30px 0;
}
.colOpacity.op-4 {
  background-position: -45px 0;
}
.panel-title {
  font-size: 1em;
}
.panel-title > a {
  display: block;
  width: 100%;
  height: 100%;
}
.stockistName {
  font-weight: bold;
  height: 2.5em;
}
.stockistAddress {
  height: 6.5em;
  overflow: hidden;
}
@media (max-width: 467px) {
  .stockistAddress {
    height: auto;
  }
}
.stockistListing {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  min-width: 185px;
}
#accordion h4 a:hover,
#accordion h4 a:focus {
  text-decoration: none;
}
#accordion h4 a span {
  margin-right: 1em;
}
#search {
  padding: 1em 1em 2em 1em;
}
#search label {
  margin-right: 1em;
}
/*# sourceMappingURL=bootstrap-theme.css.map */