/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
// Load core variables and mixins
// --------------------------------------------------

@import "variables.less";
@import "mixins.less";


//
// Buttons
// --------------------------------------------------



//
// Images
// --------------------------------------------------

.thumbnail,
.img-thumbnail {
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}

.img-space {
    margin:0.5em 0;
}


//
// Dropdowns
// --------------------------------------------------

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  #gradient > .vertical(@start-color: @dropdown-link-hover-bg; @end-color: darken(@dropdown-link-hover-bg, 5%));
  background-color: darken(@dropdown-link-hover-bg, 5%);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
  background-color: darken(@dropdown-link-active-bg, 5%);
}

.dropdown-menu > li > a {
	padding-top:0.75em;
	padding-bottom: 0.75em;
}


//
// Navbar
// --------------------------------------------------

// Default navbar
.navbar-default {
    background-color:@brand-primary2;
    border:none;
    border-radius: 0; 
}

.navbar-default .navbar-nav>li>a {
    color: #fff;
    text-shadow: none;
}

.navbar-brand,
.navbar-nav > li > a {
  color:#fff;
}

// Inverted navbar
.navbar-inverse {
  #gradient > .vertical(@start-color: lighten(@navbar-inverse-bg, 10%); @end-color: @navbar-inverse-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered; see https://github.com/twbs/bootstrap/issues/10257
  border-radius: @navbar-border-radius;
  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    #gradient > .vertical(@start-color: @navbar-inverse-link-active-bg; @end-color: lighten(@navbar-inverse-link-active-bg, 2.5%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.25));
  }

  .navbar-brand,
  .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }
}

// Undo rounded corners in static and fixed navbars
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}

// Fix active state of dropdown items in collapsed mode
@media (max-width: @grid-float-breakpoint-max) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      color: #fff;
      #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
    }
  }
}


//
// Alerts
// --------------------------------------------------

// Common styles
/*.alert {
  text-shadow: 0 1px 0 rgba(255,255,255,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.05);
  .box-shadow(@shadow);
}

// Mixin for generating new styles
.alert-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 7.5%));
  border-color: darken(@color, 15%);
}

// Apply the mixin to the alerts
.alert-success    { .alert-styles(@alert-success-bg); }
.alert-info       { .alert-styles(@alert-info-bg); }
.alert-warning    { .alert-styles(@alert-warning-bg); }
.alert-danger     { .alert-styles(@alert-danger-bg); }
*/

//
// Progress bars
// --------------------------------------------------

// Give the progress background some depth
.progress {
  #gradient > .vertical(@start-color: darken(@progress-bg, 4%); @end-color: @progress-bg)
}

// Mixin for generating new styles
.progress-bar-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 10%));
}

// Apply the mixin to the progress bars
.progress-bar            { .progress-bar-styles(@progress-bar-bg); }
.progress-bar-success    { .progress-bar-styles(@progress-bar-success-bg); }
.progress-bar-info       { .progress-bar-styles(@progress-bar-info-bg); }
.progress-bar-warning    { .progress-bar-styles(@progress-bar-warning-bg); }
.progress-bar-danger     { .progress-bar-styles(@progress-bar-danger-bg); }

// Reset the striped class because our mixins don't do multiple gradients and
// the above custom styles override the new `.progress-bar-striped` in v3.2.0.
.progress-bar-striped {
  #gradient > .striped();
}


//
// List groups
// --------------------------------------------------

.list-group {
  border-radius: @border-radius-base;
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 darken(@list-group-active-bg, 10%);
  #gradient > .vertical(@start-color: @list-group-active-bg; @end-color: darken(@list-group-active-bg, 7.5%));
  border-color: darken(@list-group-active-border, 7.5%);

  .badge {
    text-shadow: none;
  }
}


//
// Panels
// --------------------------------------------------

// Common styles
.panel {
  .box-shadow(0 1px 2px rgba(0,0,0,.05));
}

// Mixin for generating new styles
.panel-heading-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 5%));
}

//
// Wells
// --------------------------------------------------

.well {
  #gradient > .vertical(@start-color: darken(@well-bg, 5%); @end-color: @well-bg);
  border-color: darken(@well-bg, 10%);
  @shadow: inset 0 1px 3px rgba(0,0,0,.05), 0 1px 0 rgba(255,255,255,.1);
  .box-shadow(@shadow);
}


//
// Custom Styles
// --------------------------------------------------
p {
  margin: 0 0 @line-height-computed;
}
a.logo:hover {
    text-decoration: none;
}

.logo {
    @media (max-width:@grid-float-breakpoint-max) {
        display:none;
    }
}

.logoRow1 {
    background: @brand-secondary;
    color: @brand-primary;
    font-family: @logo-font;
    text-align: center;
    font-size: 8vw;
    text-decoration: none;
}
.logoRow1 {
  @media (min-width: @screen-xs-min) {
    font-size: (@font-size-base * 2.1);
  }
  @media (min-width: @screen-sm-min) {
    font-size: (@font-size-base * 3);
  }
  @media (min-width: @screen-md-min) {
   font-size: (@font-size-base * 3.5);
  }
    @media (min-width: @screen-lg-min) {
    font-size: (@font-size-base * 4);
  }
}

.logoRow2 {
    background: @brand-primary;
    color: @brand-secondary;
    font-family: @logo-font;
    text-align: center;
    font-size: 5vw;
    text-decoration: none;
}
.logoRow2 {
  @media (min-width: @screen-xs-min) {
    font-size: (@font-size-base * 1.5);
  }
  @media (min-width: @screen-sm-min) {
    font-size: (@font-size-base * 1.8);
  }
  @media (min-width: @screen-md-min) {
   font-size: (@font-size-base * 2.3);
  }
  @media (min-width: @screen-lg-min) {
    font-size: (@font-size-base * 2.7);
  }
}
.logoRow1 a:hover, .logoRow2 a:hover {
    text-decoration: none;
}
.logoRow2 a {
    color:#fff;
}
.navbar-nav .open .dropdown-menu{
    @media (max-width: @grid-float-breakpoint-max) {
        background:#fff;
        font-size:1em;
    }    
}
.navbar-nav .open .dropdown-menu li {
    @media (max-width: @screen-md-min) {
        padding-top:0.5em;
        padding-bottom:1em;
    }
}
.navbar-nav .open .dropdown-menu > li > a {
    @media (max-width: @screen-sm-max) {
        padding:0.5em 2.5em;
    }
}

#homeHero img {
	@media (min-width: @screen-xs-min) {
    	margin-bottom:1em;
	}
}

#homeHero figcaption {
    background: fade(@brand-secondary, 80%);
    width:auto;
    padding:0.5em 1em;
    position:absolute;
    bottom: 1em;
    right:1em;
	@media (max-width: @screen-xs-min) {
 		position:relative;
		bottom: 0;
		left:0;
		margin-bottom: 1em;
    }
}

.h1,.h2,.h3,.h4,.h5 {
    font-weight:bold;
}
.secondaryBG {
    background-color:@brand-secondary;
}
.navbar {
    min-height:2em;
}
.navbar-nav>li>a {
    padding-top:1em;
    padding-bottom:1em;
}
.navbar-nav>li {
    padding:0 1em;
}

.navbar-brand {
    @media (min-width: @grid-float-breakpoint-max) {
        display:none;
    }
    font-size:1em;
    padding:0;
    height:auto;
    line-height:@line-height-base;
    width:100%;
}
.navbar-toggle {
    @media (min-width: @screen-xs-min) {
        margin:-6em 1em;
        padding:0.9em;
    } 
    margin:-17vw 3vw;
    padding:2.5vw;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color:@brand-primary;
}
.navbar-toggle .icon-bar+.icon-bar {
    @media (min-width: @screen-xs-min) {
        margin-top:0.4em;
    }
    margin-top:1vw;
}
.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
    color:@brand-secondary;
}
#home .navbar {
    margin-bottom:0;
}

footer > div {
    background-color: @brand-primary;
    padding-top:2em;
    padding-bottom: 1em;
    color:#fff;
    text-align: center;
}
footer > div a {
    color:#fff;
}

.cookie-disclaimer {
	border-radius: 0;
	background-color: #231F20;
	margin-bottom: 0;
	border:none;
	color:#fff;
}
.cookie-disclaimer a {
	color:#B85856;
}

.socialFooter {
    margin-bottom:10px;
}

.socialFooter img {
    height:3em;
    margin-right:1em;
}
.alert.alert-msg {
    color: #fff;
    background-color: #6aa6a5;
    border-color: #6a90aa;
}
.alert.alert-msg a {
    color: #f2e2d2;
}

//
// Form Defaults
// --------------------------------------------------

.label {
    color: @text-color;
}

//
// Contact
// --------------------------------------------------

#contactus {
    background:@gray-lighter;
    padding:1em;
}
form label {
    font-size:(@font-size-base * 1.1);
}
.contactHeader {
    color:#fff;
    padding:0.75em;
    background:@brand-primary;
    font-size:1.5em;
}
.frozen div.input {
    background:#f9f9f9;
    padding: 1em 0.5em;
}

p.contactEmail {
	.img-retina('/img/contact/contact-email.png'; '/img/contact/contact-email@2x.png'; 40px; 29px) ;
	background-repeat: no-repeat;
	padding:0.5em 0 0.5em 4em;
	margin-bottom:2em;
}
p.contactPhone {
	.img-retina('/img/contact/contact-phone.png'; '/img/contact/contact-phone@2x.png'; 40px; 46px) ;
	background-repeat: no-repeat;
	padding:1.2em 0 1.2em 4em;
	margin-bottom:2em;
}
p.contactAddress {
	.img-retina('/img/contact/contact-address.png'; '/img/contact/contact-address@2x.png'; 40px; 51px);
	background-repeat: no-repeat;
	padding:0 0 0 4em;
	margin-bottom:2em;
}
.contactSocial {
    padding:1em 0 1em 4em;
    margin: 0 0 @line-height-computed;
    display:block;
    background-size: 40px;
	background-repeat: no-repeat;
}
.contactFB {
    background-image: url('/img/contact/contact-facebook.svg');
    }
.contactTW {
	background-image: url('/img/contact/contact-twitter.svg');
}
.contactIN {
	background-image: url('/img/contact/contact-instagram.svg');
}
.has-error .form-control, .has-error label {
	color:@brand-danger;
}

.envStatus {
	text-align: center;
	padding:10px;
	background-color:crimson;
	color:#fff;
}


//
// Stockist
// --------------------------------------------------
.required {
    display:none;
}

#stockists .panel-body {
    max-height: 50em;
    overflow-y: scroll;
}
.stockistLogo {
	height:45px;
	width:100%;
	vertical-align: middle;
}
.stockistLogo img {
	max-width:100%;
	max-height:45px;
}


//
// Category Page
// --------------------------------------------------
.panel-heading {
    background:@brand-primary;
    color:#fff;
    font-size:@font-size-h4;
}
.catCaption {
    position: relative;
    margin-bottom:2em;
}
.catCaption img{
    display:block;
    width: 100%;
    min-height:199px;
    background-color:#f0f0f0;
}
.catCaption span {
    width:100%;
    padding:1em 0.5em;
    font-weight:bold;
    position:absolute;
    bottom: 0;
    left:0;
    background: fade(@brand-secondary, 80%);
}

//
// Product Page
// --------------------------------------------------
.table {
    margin-top:1em;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    border-top:none;
}
#swatch .row {
    margin-bottom:1em;
}
.colourSwatch {
    height:4.5em;
}
.colourSwatch img {
    width:100%;
    height: 100%;
}
.colName {
    font-weight:bold;
}
.colCode, .colSeries {
    font-size:@font-size-small;
}
.colOpacity {
    height:15px;
    width:15px;
    background: url('/img/opacity-sprite.png') no-repeat;
    margin-top:1.5em;
}
.colOpacity.op-1{
    background-position: 0 0;
}
.colOpacity.op-2{
    background-position: -15px 0;
}
.colOpacity.op-3{
    background-position: -30px 0;
}
.colOpacity.op-4{
    background-position: -45px 0;
}

//
// Stockist Page
// --------------------------------------------------
.panel-title {
    font-size:1em;
}
.panel-title > a {
    display:block;
    width:100%;
    height:100%;
}
.stockistName {
    font-weight: bold;
    height:2.5em;
}
.stockistAddress {
    @media (max-width: 467px) {
        height:auto;
    }
    height: 6.5em;
    overflow:hidden; 
}
.stockistListing {
    margin-top:0.5em;
    margin-bottom:0.5em;
    min-width:185px;
}
#accordion h4 a:hover, #accordion h4 a:focus {
    text-decoration: none;
}
#accordion h4 a span {
    margin-right:1em;
}

#search {
	padding:1em 1em 2em 1em;
}
#search label {
	margin-right:1em;
}